import { useRef } from "react";
import {
  Img,
  Box,
  Drawer,
  Skeleton,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import parse from "html-react-parser";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const listItem = {
  listItem: {
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderBottomWidth: 0.2,
    borderTopWidth: 0,
    borderColor: "#CED5DE",
    width: "95%",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",

    borderStyle: "outset",
  },
  listFirstItem: {
    borderWidth: 0.2,
    borderColor: "#CED5DE",
    width: "95%",
    alignSelf: "center",

    marginLeft: "auto",
    marginRight: "auto",
    borderStyle: "outset",
  },
};
export default function Index({
  drawerOpen,
  primaryOpen,
  closeDrawer,
  openMainMenu,
  secondaryOpen,
  productCategories,
  openProductPrimaryMenu,
  openProductSecondaryMenu,
}) {
  const btnRef = useRef();
  const router = useRouter();
  const catDictionary = {};
  const primaryCategories = productCategories.filter((cat) => !cat.parent);
  primaryCategories.forEach((obj) => {
    obj.subCategory = [];
    catDictionary[obj.id] = obj;
  });
  productCategories.forEach((obj) => {
    if (obj.parent) {
      catDictionary[obj.parent].subCategory.push(obj);
    }
  });

  const mainMenu = (
    <List>
      <ListItem button key="products" style={listItem.listFirstItem}>
        <ListItemText
          primary="Products"
          onClick={() => {
            router.push("/shop");
            closeDrawer();
          }}
        />

        <ChevronRightIcon onClick={openProductPrimaryMenu} />
      </ListItem>
      <ListItem button key="blogs" style={listItem.listItem}>
        <ListItemText
          primary="Blogs"
          onClick={() => {
            router.push("/blogs");
            closeDrawer();
          }}
        />
      </ListItem>
      <ListItem
        button
        key="contact-us"
        onClick={() => {
          router.push("/contact-us");
          closeDrawer();
        }}
        style={listItem.listItem}
      >
        <ListItemText primary="Contact Us" />
      </ListItem>
      <ListItem
        button
        key="about-us"
        onClick={() => {
          router.push("/about-us");
          closeDrawer();
        }}
        style={listItem.listItem}
      >
        <ListItemText primary="About Us" />
      </ListItem>
      <ListItem
        button
        key="my-account"
        onClick={() => {
          router.push("/my-account");
          closeDrawer();
        }}
        style={listItem.listItem}
      >
        <ListItemText primary="My Account" />
      </ListItem>
    </List>
  );
  const primaryProductCategories = (
    <>
      <ListItem button onClick={openMainMenu} style={listItem.listFirstItem}>
        <ListItemText primary="Back" />
        <ChevronLeftIcon />
      </ListItem>
      <List>
        {primaryCategories.map((cat) => (
          <ListItem button key={cat.id} style={listItem.listItem}>
            <ListItemText
              primary={parse(cat.name)}
              onClick={() => {
                router.push(`/product-category/${cat.slug}`);
                closeDrawer();
              }}
            />
            <ChevronRightIcon onClick={() => openProductSecondaryMenu(cat)} />
          </ListItem>
        ))}
      </List>
    </>
  );
  const secondaryProductCategories = (
    <>
      <ListItem
        button
        onClick={openProductPrimaryMenu}
        style={listItem.listFirstItem}
      >
        <ListItemText primary="Back" />
        <ChevronLeftIcon />
      </ListItem>
      <List>
        {secondaryOpen &&
          catDictionary[secondaryOpen].subCategory.map((cat) => {
            const parent = productCategories.find(
              (category) => category.id === cat.parent
            );
            return (
              <ListItem
                button
                key={cat.id}
                onClick={() => {
                  router.push(`/product-category/${parent.slug}/${cat.slug}`);
                  closeDrawer();
                }}
                style={listItem.listItem}
              >
                <ListItemText primary={parse(cat.name)} />
              </ListItem>
            );
          })}
      </List>
    </>
  );

  return (
    <Drawer
      size="xs"
      placement="right"
      isOpen={drawerOpen}
      onClose={closeDrawer}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton color="maroon" alignSelf="flex-start" />
          <DrawerHeader bg="white" />
          <Img
            mt="1"
            h="62px"
            width="90%"
            objectFit="contain"
            fallback={<Skeleton w="100%" />}
            onClick={() => router.push("/")}
            src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo_header.png`}
            _hover={{
              cursor: "pointer",
            }}
          />
          <Box mt="3"></Box>
          <Box overflowY="auto" mt="3">
            {!primaryOpen && !secondaryOpen && mainMenu}
            {primaryOpen && primaryProductCategories}
            {secondaryOpen && secondaryProductCategories}
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
