import { Text } from "./components/text";
import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  "2xl": "1450px",
});

const colors = {
  black: "#000",
  white: "#fff",
  light: "#F2F4F6",
  maroon: "#8F1838",
  golden: "#D0A152",
  titleColor: "#2A3037",
  detailsColor: "#49586B",
  cartItemBorder: "#CED5DE",
};

const theme = extendTheme({
  colors,
  breakpoints,
  components: {
    Text,
  },
});

export default theme;
