import Cookies from "js-cookie";

export const createCheckoutData = (
  cart,
  billingAddress,
  shippingAddress,
  shipToDifferentAddress
) => {
  const billing = serializeAddress(billingAddress);
  const shipping = serializeAddress(shippingAddress);

  return {
    payment_method: "paytm",
    payment_method_title: "",
    set_paid: false,
    billing,
    shipping: shipToDifferentAddress ? shipping : billing,
    line_items: cart.map((product) => ({
      product_id: product.id,
      quantity: product.quantity,
    }))
  };
};

export const serializeAddress = (address) => {
  return {
    first_name: address.firstName,
    last_name: address.lastName,
    address_1: address.address1,
    address_2: address.address2,
    city: address.city,
    state: address.state,
    postcode: address.postcode,
    country: address.country,
    email: address.email,
    phone: address.phone,
  };
};
export const deserializeAddress = (address) => {
  return {
    city: address.city,
    email: address.email,
    state: address.state,
    phone: address.phone,
    country: address.country,
    postcode: address.postcode,
    lastName: address.last_name,
    firstName: address.first_name,
    address1: address.address_1,
    address2: address.address_2,
  };
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function setCookie(key, value) {
  Cookies.set(key, JSON.stringify(value));
}

export function getYoutubeVideoDetails(src) {
  let thumbnailImage, videoId;

  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  const match = src.match(regExp);

  if (match && match[2].length == 11) {
    thumbnailImage = `https://img.youtube.com/vi/${match[2]}/0.jpg`;
    videoId = match[2];
  }

  return [thumbnailImage, videoId]
}
