import {
  Avatar,
  AvatarBadge,
  Box,
  Center,
  Flex,
  Img,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

import { useDispatch } from "react-redux";
import ReactSelect from "react-select";

// import Megamenu from "./megamenu";
import CartIcon from "../../../assets/svg/cart";
import { openCart } from "../../../redux/actions";
import { capitalizeFirstLetter } from "./../../../utils/functions";

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderWidth: 0,
    borderRadius: 0,
    borderColor: "#8F1838",
    backgroundColor: "white",
    borderBottomWidth: isFocused ? 2 : 1,
    boxShadow: isFocused ? "#8F1838" : "",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (styles) => ({ ...styles, fontSize: 14 }),
  options: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
};

const Header = ({ cart, searchableProducts }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState(null);

  const handleSearchSelect = async (selectedOption) => {
    router.push({ pathname: `/product/${selectedOption.value}` });
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      router.push({ pathname: "/search", query: { s: e.target.value } });
    } else {
      setSearchString(e.target.value);
    }
  };

  return (
    <>
      <Flex
        h="80px"
        bg="white"
        d={{ base: "none", xl: "flex" }}
        width={{ base: "90%", "2xl": "1350px" }}
      >
        <Flex w="60%">
          <Center>
            <Img
              d={{ base: "none", xl: "flex" }}
              fallback={<Skeleton w="100%" />}
              onClick={() => router.push("/")}
              h={["32px", "32px", "32px", "52px"]}
              src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo_header.png`}
              _hover={{
                cursor: "pointer",
              }}
            />
            <Img
              _hover={{ cursor: "pointer" }}
              fallback={<Skeleton w="100%" />}
              onClick={() => router.push("/")}
              src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo.png`}
              h={["32px", "32px", "32px", "52px"]}
              d={{ base: "none", lg: "flex", xl: "none" }}
            />
          </Center>
          <Flex align="center" w={{ lg: "95%", xl: "75%" }}>
            <Link href="/shop">
              <Text
                variant="headerText"
                _hover={{ cursor: "pointer" }}
                // onClick={() => router.push("/shop")}
                ml={{ lg: "40px", xl: "45px", "2xl": "75px" }}
              >
                Products
              </Text>
            </Link>
            <Link href="/blogs">
              <Text
                variant="headerText"
                _hover={{ cursor: "pointer" }}
                // onClick={() => router.push("/shop")}
                ml={{ lg: "40px", xl: "45px", "2xl": "75px" }}
              >
                Blogs
              </Text>
            </Link>
            <Link href="/contact-us">
              <Text
                variant="headerText"
                _hover={{ cursor: "pointer" }}
                // onClick={() => router.push("/contact-us")}
                ml={{ lg: "40px", xl: "45px", "2xl": "75px" }}
              >
                Contact Us
              </Text>
            </Link>
            <Link href="/about-us">
              <Text
                variant="headerText"
                _hover={{ cursor: "pointer" }}
                // onClick={() => router.push("/about-us")}
                ml={{ lg: "40px", xl: "45px", "2xl": "75px" }}
              >
                About Us
              </Text>
            </Link>
          </Flex>
        </Flex>

        <Flex w="40%" align="center" justifyContent="flex-end">
          <Flex w="55%">
            <Box w="100%" width={"300px"}>
              <ReactSelect
                placeholder="Search by product name"
                value={searchString}
                styles={colourStyles}
                onKeyDown={handleSearch}
                options={searchableProducts.map((p) => ({
                  value: p.slug,
                  label: p.name.split(" ").map(w => ` ${capitalizeFirstLetter(w)}`),
                }))}
                onFocus={() => setSearchString(null)}
                onChange={handleSearchSelect}
              />
            </Box>
          </Flex>
          <Flex w="40%" align="center" justifyContent="flex-end">
            <Avatar bg="#fff" style={{ marginRight: "5px" }}>
              <Flex>
                <CartIcon
                  width="24"
                  height="24"
                  color="maroon"
                  onClick={() => dispatch(openCart())}
                  style={{ marginRight: "5px" }}
                />
                {cart && cart.length > 0 && (
                  <Box ml="5px">
                    <AvatarBadge bg="#8f1838" boxSize="1em">
                      <Text
                        fontWeight="bold"
                        variant="aboutUsText"
                        fontSize="12px"
                        color={"#fff"}
                      >
                        {cart.length}
                      </Text>
                    </AvatarBadge>
                  </Box>
                )}
              </Flex>
            </Avatar>
            <Link href="/my-account">
              <Text
                variant="headerText"
                ml={{ lg: "6%", xl: "10%" }}
                _hover={{ cursor: "pointer" }}
                onClick={() => router.push("/my-account")}
              >
                My Account
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      {/* <Popper open={isOpen} id="mega-menu">
        <Megamenu
          handleClick={handleClick}
          productCategories={productCategories}
        />
      </Popper> */}
    </>
  );
};
export default Header;
