import { useSelector } from "react-redux";
import { Center, Box } from "@chakra-ui/react";

import MobileHeader from "./mobile";
import DesktopHeader from "./desktop";

export default function Index() {
  const { cart, productCategories, searchableProducts } = useSelector((state) => state);

  return (
    <Box
      width="100%"
      position="sticky"
      zIndex={3}
      top="0px"
      backgroundColor="#fff"
    >
      <Center>
        <MobileHeader cart={cart} productCategories={productCategories} searchableProducts={searchableProducts} />
        <DesktopHeader cart={cart} searchableProducts={searchableProducts} />
      </Center>
    </Box>
  );
}
