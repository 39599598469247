import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import DrawerCart from "../../drawerCart";
import Footer from "../footer";
import Header from "../header";

import { fetchSearchableProductsAction } from "../../../redux/actions";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const [desktop, setDesktop] = useState();
  const [mobile, setMobile] = useState();
  const [ipad, setIpad] = useState();

  useEffect(() => {
    setMobile((window.innerHeight / 100) * 45);
    setIpad((window.innerHeight / 100) * 60);
    setDesktop((window.innerHeight / 100) * 65);
    dispatch(fetchSearchableProductsAction())
  }, []);

  return (
    <>
      <Header />
      <Box
        minHeight={[mobile, mobile, mobile, ipad, desktop]}
        maxHeight="fit-content"
      >
        {children}
      </Box>
      <Footer />
      <DrawerCart />
    </>
  );
};
export default Layout;
