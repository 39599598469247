import {
  Box,
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";

import CartItem from "./cartItem";
import EmptyCart from "./emptyCart";
import Button from "../common/button";

import IsDoctor from "../permission/isDoctor";

import { closeCart } from "../../redux/actions";

export default function Index() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { cart, displayCart } = useSelector((state) => state);

  const subTotal = cart.reduce(
    (a, b) => a + b.quantity * parseFloat(b.price),
    0
  );
  const subTotalDoctorPrice = cart.reduce(
    (a, b) => a + b.quantity * parseFloat(b.doctorPrice),
    0
  );

  return (
    <Drawer
      size="xs"
      placement="right"
      isOpen={displayCart}
      onClose={() => dispatch(closeCart())}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton color="white" />
          <DrawerHeader bg="maroon" alignItems="center" justifyContent="center">
            <Text color="white" variant="productCardTitle">
              My cart items
            </Text>
          </DrawerHeader>
          <DrawerBody p="0">
            {cart.length === 0 ? (
              <EmptyCart />
            ) : (
              <>
                {cart.map((product) => (
                  <CartItem key={product.id} product={product} />
                ))}
              </>
            )}
          </DrawerBody>
          <DrawerFooter
            bg="light"
            width="100%"
            height="135px"
            borderTopWidth="1"
            alignItems="center"
            flexDirection="column"
            borderTopColor="maroon"
            justifyContent="space-between"
          >
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Text variant="productCardTitle">Subtotal :</Text>
              <Text ml={3} variant="priceText">
                ₹
                {IsDoctor()
                  ? parseFloat(subTotalDoctorPrice).toFixed(2)
                  : parseFloat(subTotal).toFixed(2)}
              </Text>
            </Flex>
            <Flex
              mt={3}
              px="10px"
              width="100%"
              alignSelf="center"
              justifyContent="space-between"
            >
              <Button
                px={5}
                title="View cart"
                backgroundColor="maroon"
                handleClick={() => {
                  dispatch(closeCart());
                  router.push("/cart");
                }}
              />
              <Button
                px={5}
                title="Checkout"
                backgroundColor="maroon"
                handleClick={() => {
                  dispatch(closeCart());
                  router.push("/checkout");
                }}
              />
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
