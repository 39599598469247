import { useRouter } from "next/router";
import { Box, Img, Flex, Text, Input, Skeleton } from "@chakra-ui/react";

import Button from "../button";
import Facebook from "../../../assets/svg/facebook";
import LinkedIn from "../../../assets/svg/linkedin";
import Youtube from "../../../assets/svg/youtube";

const Footer = () => {
  const router = useRouter();

  const aboutCompany = (
    <Box
      flexWrap="wrap"
      width={{ base: "100%", md: "25%" }}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text variant="footerTitleText" mb={2}>
        About Company
      </Text>
      <Text
        _hover={{ cursor: "pointer" }}
        variant="footerDescriptionText"
        onClick={() => router.push("/about-us")}
      >
        About Us
      </Text>
      <Text
        _hover={{ cursor: "pointer" }}
        variant="footerDescriptionText"
        onClick={() => router.push("/privacy-policy")}
      >
        Privacy Policy
      </Text>
      <Text
        _hover={{ cursor: "pointer" }}
        variant="footerDescriptionText"
        onClick={() => router.push("/terms-of-use")}
      >
        Terms of Use
      </Text>
      <Text
        _hover={{ cursor: "pointer" }}
        variant="footerDescriptionText"
        onClick={() => router.push("/return-policy")}
      >
        Return Policy
      </Text>
      <Text
        _hover={{ cursor: "pointer" }}
        variant="footerDescriptionText"
        onClick={() => router.push("/shipping-policy")}
      >
        Shipping Policy
      </Text>
    </Box>
  );

  const contact = (
    <Box
      flexWrap="wrap"
      mt={{ base: 5, md: "0" }}
      width={{ base: "100%", md: "30%" }}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text variant="footerTitleText" mb={2}>
        Contact
      </Text>
      <Text variant="footerDescriptionText" mb={2}>
        A-3, Phase II, Road 9 Gallops Industrial Park, Vasna-Chacharwadi, Ta.
        Sanand, Ahmedabad - 382213, Gujarat.
      </Text>
      <Text variant="footerDescriptionText" mb={5} mt={2}>
        M: +91-9426075439
      </Text>
      <Text variant="footerTitleText">Follow us on</Text>
      <Flex
        mt={2}
        width="100%"
        justifyContent={{ base: "center", md: "flex-start" }}
      >
        <a
          target="_blank"
          _hover={{ cursor: "pointer" }}
          href="https://www.facebook.com/Dr.Vasishths/"
        >
          <Facebook style={{ marginRight: "20" }} />
        </a>
        <a
          target="_blank"
          _hover={{ cursor: "pointer" }}
          href="https://in.linkedin.com/company/dr-vasishth-s-ayuremedies"
        >
          <LinkedIn style={{ marginRight: "20" }} />
        </a>
        <a
          target="_blank"
          _hover={{ cursor: "pointer" }}
          href="https://www.youtube.com/user/ayurem"
        >
          <Youtube />
        </a>
      </Flex>
    </Box>
  );

  const keepInTouch = (
    <Box
      flexWrap="wrap"
      mt={{ base: 5, md: "0" }}
      width={{ base: "100%", md: "40%" }}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text variant="footerTitleText" mb={2}>
        Keep in Touch
      </Text>
      <Text variant="footerDescriptionText" mb={5}>
        Sign up for email updates on products offers and discounts.
      </Text>
      <Input
        mb={2}
        w="100%"
        size="md"
        bg="white"
        opacity="0.5"
        color="white"
        fontSize="16px"
        variant="filled"
        borderRadius="4px"
        placeholder="Your Email"
        placeholdercolor="white"
      />
      <Flex
        width="100%"
        alignItems={"center"}
        justifyContent="flex-start" 
      >
        <Button
          py="12px"
          px="18.5px"
          height="45px"
          title="Submit"
          borderRadius={2}
          backgroundColor="golden"
        />
        <Img
          ml={"2"}
          d="flex"
          fallback={<Skeleton w="100%" />}
          onClick={() => router.push("/")}
          h="35px"
          src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/razorpay_logo.png`}
          _hover={{
            cursor: "pointer",
          }}
        />
      </Flex>
    </Box>

  );
  return (
    <Flex
      mt={5}
      py="10"
      w="100%"
      left="0"
      right="0"
      bottom="0"
      bg="maroon"
      as="footer"
      marginTop="auto"
      h={"fit-content"}
      position="static"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Flex
        alignSelf="center"
        justifyContent="space-between"
        width={{ base: "90%", "2xl": "1350px" }}
        px={["20px", null, null, null, "0"]}
        flexDirection={{ base: "column", xl: "row" }}
      >
        <Box
          flexWrap="wrap"
          maxWidth="100%"
          alignItems="center"
          justifyContent="center"
          width={{ base: "100%", xl: "25%" }}
        >
          <Img
            objectFit="contain"
            mx={{ base: "auto", md: "0" }}
            fallback={<Skeleton w="100%" />}
            src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo_footer.png`}
          />
          <Text
            mt="5"
            variant="footerDescriptionText"
            textAlign={{ base: "center", md: "left" }}
          >
            Dr.Vasishth’s is playing a unique role of improving lives by
            offering effective and disease-modifying ayurveda healthcare
            products.
          </Text>
        </Box>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          mt={{ base: 5, xl: "0" }}
          pl={{ base: "0", xl: "10px" }}
          width={{ base: "100%", xl: "75%" }}
          justifyContent={{
            base: "center",
            xl: "space-around",
            md: "space-between",
          }}
        >
          {aboutCompany}
          {contact}
          {keepInTouch}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Footer;
