import * as React from "react";

function index(props) {
  return (
    <svg
      width={props?.width}
      height={props?.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"ic_cardt"}</title>
      <path
        d="M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zM2 3c0 .55.45 1 1 1h1l3.6 7.59-1.35 2.44C5.52 15.37 6.48 17 8 17h11c.55 0 1-.45 1-1s-.45-1-1-1H8l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0021.01 4H6.21l-.67-1.43a.993.993 0 00-.9-.57H3c-.55 0-1 .45-1 1zm16 15c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
        fill={props?.color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default index;
