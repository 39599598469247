import React from "react";
import {
  Box,
  Img,
  Flex,
  Text,
  Button,
  Skeleton,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import {
  removeFromCart,
  increaseCartQuantity,
  decreaseCartQuantity,
} from "../../redux/actions";

import IsDoctor from "../permission/isDoctor";

import Delete from "../../assets/svg/delete";
import AddIcon from "../../assets/svg/add_icon";
import LessIcon from "../../assets/svg/less_icon";

const CardItem = ({ product }) => {
  const dispatch = useDispatch();
  const { id, name, quantity, price, image, doctorPrice } = product;

  return (
    <Flex px="20px" pt="20px" width="100%" height="140px">
      <Flex
        width="100%"
        height="100%"
        borderBottomWidth={1}
        borderBottomColor="light"
      >
        <Img
          lazy="true"
          width="100px"
          height="100px"
          src={image?.src}
          overflow="hidden"
          objectFit="contain"
          alt={image?.name}
          fallback={<Skeleton w="100%" />}
        />
        <Box ml="2">
          <Text variant="productCardTitle" fontSize="17px">
            {name}
          </Text>
          <ButtonGroup size="sm" mt="2" isAttached variant="outline">
            <IconButton
              disabled={quantity === 1 && true}
              onClick={() => dispatch(decreaseCartQuantity(id))}
              icon={<LessIcon height="15" width="15" color="maroon" />}
            />
            <Button mr="-px">{quantity}</Button>
            <IconButton
              onClick={() => dispatch(increaseCartQuantity(id))}
              icon={<AddIcon width="15" height="15" color="maroon" />}
            />
          </ButtonGroup>
          <Flex
            mt="2"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text ml="10px" variant="priceText">
              ₹
              {IsDoctor()
                ? parseFloat(doctorPrice).toFixed(2)
                : parseFloat(price).toFixed(2)}
            </Text>
            <Delete
              width="20"
              height="20"
              color="maroon"
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(removeFromCart(id))}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
export default CardItem;
