import * as React from "react"

function LinkedIn(props) {
  return (
    <svg
      width={38}
      height={37}
      viewBox="0 0 38 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19} cy={19} r={18} fill="#8F1838" stroke="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.848 15.435c1.155 0 1.879-.763 1.868-1.717-.01-.975-.713-1.718-1.848-1.718-1.125 0-1.868.743-1.868 1.718 0 .954.713 1.717 1.828 1.717h.02zm6.79 11.31v-5.554c0-.301.03-.593.11-.814.242-.592.784-1.205 1.698-1.205 1.196 0 1.678.914 1.678 2.25v5.324h3.305V21.04c0-3.053-1.628-4.48-3.807-4.48-1.707 0-2.498.906-2.942 1.583l-.042.064v-1.416h-3.304s.04.934 0 9.955h3.304zm-5.132 0v-9.954H11.19v9.955h3.315z"
        fill="#fff"
      />
    </svg>
  )
}

export default LinkedIn
