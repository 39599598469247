import * as React from "react"

function Youtube(props) {
  return (
    <svg
      width={38}
      height={37}
      viewBox="0 0 38 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19} cy={19} r={18} fill="#8F1838" stroke="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.74 25.606c.944-.11 1.728-.834 1.939-1.818.321-1.396.321-2.923.321-4.36 0-1.436 0-2.963-.311-4.359-.221-.984-1.005-1.707-1.949-1.818C23.51 13 21.25 13 19 13s-4.51 0-6.74.251c-.944.11-1.718.834-1.939 1.818-.285 1.241-.317 2.585-.32 3.878l-.001.482c0 1.436 0 2.963.311 4.359.221.984 1.005 1.708 1.949 1.818 1.951.22 3.926.247 5.896.25l.844.001c2.25 0 4.51 0 6.74-.251zm-8.669-2.32c-.11 0-.21-.03-.31-.08a.65.65 0 01-.332-.563v-6.429a.65.65 0 01.331-.562c.2-.11.452-.11.653.02l5.143 3.214c.19.11.301.322.301.543 0 .22-.11.431-.301.542l-5.143 3.214c-.1.07-.221.1-.342.1z"
        fill="#fff"
      />
    </svg>
  )
}

export default Youtube
