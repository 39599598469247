import React from "react";
import Head from "next/head";

// for show route progress
import dynamic from "next/dynamic";
import "nprogress/nprogress.css";

import { ChakraProvider } from "@chakra-ui/react";

import { wrapper } from "../src/redux/store";

import theme from "../src/themes";
import Layout from "../src/components/common/layout";
import "swiper/swiper.scss";
import "../styles/globals.css";
import "../styles/nprogress.css";
import "swiper/swiper-bundle.css";
import "react-modal-video/scss/modal-video.scss";
import "swiper/components/effect-fade/effect-fade.scss";

const TopProgressBar = dynamic(
  () => {
    return import("../src/components/TopProgressBar");
  },
  { ssr: false }
);
function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Dr.Vasishth's AyuRemedies</title>
        <link rel="icon" href="/logo.png" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
          `,
          }}
        />
      </Head>
      <body>
        <ChakraProvider resetCSS theme={theme}>
          <Layout>
            <TopProgressBar />
            <Component {...pageProps} />
          </Layout>
        </ChakraProvider>
      </body>
    </>
  );
}

export default wrapper.withRedux(MyApp);
