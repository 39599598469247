import * as React from "react"

function Facebook(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={19} r={18} fill="#8F1838" stroke="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.635 27.714V20.09h2.551l.392-2.973h-2.943V15.22c0-.854.231-1.447 1.467-1.447h1.577V11.12c-.272-.04-1.206-.12-2.29-.12-2.27 0-3.828 1.386-3.828 3.928v2.19H14v2.972h2.561v7.624h3.074z"
        fill="#fff"
      />
    </svg>
  )
}

export default Facebook
