import { combineReducers } from "redux";
import { HYDRATE } from "next-redux-wrapper";

import * as types from "./types";

const cartReducer = (state = [], { type, payload }) => {
  let cart = [];
  switch (type) {
    case `${types.ADD_TO_CART}`:
      const isAlreadyAdded = state.find((product) => product.id === payload.id);
      if (isAlreadyAdded) {
        return state;
      }

      const { id, images, slug, name, price, doctor_price, short_description } =
        payload;
      cart = [
        ...state,
        {
          quantity: 1,
          id,
          name,
          slug,
          image: images[0],
          price: price,
          doctorPrice: doctor_price,
          shortDescription: short_description,
        },
      ];
      return cart;

    case `${types.EMPTY_CART}`:
      return [];

    case `${types.REMOVE_FROM_CART}`:
      const filteredProducts = state.filter(
        (product) => product.id !== payload
      );
      cart = [...filteredProducts];
      return cart;

    case `${types.INCREASE_CART_QUANTITY}`:
      const productToIncrease = state.find((product) => product.id === payload);
      productToIncrease["quantity"] += 1;
      cart = [...state];
      return cart;

    case `${types.DECREASE_CART_QUANTITY}`:
      const productToDecrease = state.find((product) => product.id === payload);
      productToDecrease["quantity"] -= 1;
      cart = [...state];
      return cart;

    default:
      return state;
  }
};

const productsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case `${types.FETCH_PRODUCTS}_${types.SUCCESS}`:
      return [...payload.products];
    default:
      return state;
  }
};

const searchableProductsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case `${types.FETCH_SEARCHABLE_PRODUCTS}_${types.SUCCESS}`:
      return [...payload.searchableProducts];
    default:
      return state;
  }
};

const productCategoriesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case `${types.FETCH_PRODUCT_CATEGORIES}_${types.SUCCESS}`:
      return [...payload.categories];
    default:
      return state;
  }
};

const displayCartReducer = (state = false, { type }) => {
  switch (type) {
    case types.OPEN_CART:
      return true;
    case types.CLOSE_CART:
      return false;
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  cart: cartReducer,
  products: productsReducer,
  displayCart: displayCartReducer,
  productCategories: productCategoriesReducer,
  searchableProducts: searchableProductsReducer
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    if (state.cart) nextState.cart = state.cart;
    if (state.searchableProducts) nextState.searchableProducts = state.searchableProducts;
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export default reducer;
