import * as React from "react"

function Menu(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props?.height} width={props?.width} viewBox="0 0 512 512" fill={props?.color }{...props}>
      <path d="M492 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zM492 76H20C8.954 76 0 84.954 0 96s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zM492 396H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  )
}

export default Menu
