export const Text = {
  variants: {
    titleText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "titleColor",
    },
    descriptionText: {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    headerText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "titleColor",
    },
    cartBold: {
      fontSize: "16px",
      fontWeight: "700",
      fontFamily: "Lato",
      color: "titleColor",
    },
    cartBoldMaroon: {
      fontSize: "17px",
      fontWeight: "700",
      fontFamily: "Lato",
      color: "titleColor",
    },
    cartItemDescription: {
      flexWrap: "wrap",
      fontSize: "15px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    cartRegular: {
      fontSize: "17px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    footerDescriptionText: {
      color: "white",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Lato",
    },
    footerTitleText: {
      color: "white",
      fontSize: "17px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    productCardTitle: {
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "Lato",
      color: "titleColor",
    },
    productCardDescription: {
      flexWrap: "wrap",
      fontWeight: "400",
      fontSize: "16px",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    drawerTitleText: {
      fontSize: "26px",
      color: "#3A3A3A",
      fontWeight: "400",
      fontFamily: "Lato",
      textAlign: "center",
    },
    priceText: {
      color: "maroon",
      fontSize: "16px",
      fontWeight: "700",
    },
    contactUsHeading: {
      color: "#2A3037",
      fontSize: "24px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    contactUsBold: {
      color: "#2A3037",
      fontSize: "17px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    contactUsRegular: {
      color: "#2A3037",
      fontSize: "17px",
      fontFamily: "Lato",
    },
    aboutUsHeading: {
      color: "#8F1838",
      fontSize: "30px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    aboutUsText: {
      fontSize: "18px",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    megaMenuHeading: {
      color: "maroon",
      fontSize: "19px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    megaMenuSubHeading: {
      fontSize: "16px",
      fontFamily: "Lato",
      color: "titleColor",
    },
    headingHomePage: {
      fontSize: "30px",
      fontWeight: "700",
      fontFamily: "Lato",
    },
    descriptionHomePage: {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Lato",
    },
    myAccountDescriptionLabel: {
      as: "b",
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "Lato",
    },
    myAccountDescriptionText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    orderListDescriptionText: {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Lato",
      color: "detailsColor",
    },
    orderTableHeaderText: {
      color: "#2A3037",
      fontSize: "22px",
      fontWeight: "bold",
      fontFamily: "Lato",
    },
  },
};
